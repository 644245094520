
//declare let __webpack_public_path__;
import { PoseEngine } from "@geenee/bodyprocessors";
import { Recorder } from "@geenee/armature";
import { OutfitParams, PoseOutfitPlugin } from "@geenee/bodyrenderers-three";
import { AvatarRenderer } from "./avatarrenderer";
import "./index.css";
import swal from 'sweetalert';
//import "../user_data/*";

import { Server } from "http";
import { saveAs } from 'file-saver';
import { EnvironmentPlugin } from "webpack";
//import { send_img } from "../python.js";
import { PythonShell } from 'python-shell';
import path from 'path';
import * as nsUrl from 'url';
//import * as request from 'request';
import fileupload from 'express-fileupload';

import spawn from 'child_process';

import express from "express";
import { Application, Request, Response } from 'express';
import { post } from "../router";

import FormData from 'form-data';
import { request } from 'http';
import { createReadStream } from 'fs';

import dynamo from '../dynamodb-controller.js';

import * as AWS from 'aws-sdk';

import * as config from '../config.js';
import * as st_config from '../st_config.js';

import Stripe from 'stripe';
import { param } from "./get_params";

import isEmailValid from '../email_check.js';

import * as email_check from 'email-validator';

import * as fs from 'fs';

import nodemailer from 'nodemailer';
import jwt from 'jsonwebtoken';
import util from 'util';
import { AdditiveAnimationBlendMode } from "three";

var configs: Stripe.StripeConfig = { apiVersion: "2022-11-15" };
const stripe = new Stripe(st_config.remote_config.sKey, configs);
//const paymentIntent = stripe.paymentIntents.retrieve(
//    'pi_1EUmy5285d61s2cIUDDd7XEQ'
//);



//const customer = stripe.customers.retrieve(customer_id, {expand: ['invoice.subscription']});
//JSON.parse(customer.toString())

AWS.config.update(config.aws_remote_config);
const docClient = new AWS.DynamoDB.DocumentClient();
const sources3 = new AWS.S3();

async function customer_get(user_email:string) {
    const customers = await stripe.customers.search({
        query: 'email:\'' + user_email + '\'',
    });
    
    var cus_ids = [];
    for (let index = 0; index < customers.data.length; index++) {
        cus_ids.push(customers.data[index].id);
    }
    return cus_ids;
}


//async function subscription_get(order: string) {
//    const subscription = await stripe.subscriptions.search({
//        query: 'status:\'active\' AND metadata[\'order_id\']:\'' + order + '\'',
//    });

//    return subscription.data[0].start_date;
//}

async function subscription_get(customer_id: string) {
    const subscription = await stripe.subscriptions.list({
        status: "active",
        customer: customer_id,
    });

    if (subscription.data[0] == null) {
        const subscription2 = await stripe.subscriptions.list({
            status: "trialing",
            customer: customer_id,
        });

        if (subscription2.data[0] != null) {
            return subscription2.data[0].start_date;
        } else {
            return "";
        }
    }

    return subscription.data[0].start_date;
}


async function customer_sub_tot(user_email:string) {
    var customer_ids = [];
    customer_get(user_email).then(res => { customer_ids = res; });
    //customer_id = document.getElementsByName('email')[0].value;

    await new Promise(resolve => setTimeout(resolve, 500));
    // Timestamp in seconds

    if (customer_ids != null) {
        var subscription_created;
        for (var id of customer_ids) {
            subscription_get(id).then(res => {
                if (res != null && res != "") {
                    subscription_created = res; } });
        }

        await new Promise(resolve => setTimeout(resolve, 500));

        if (subscription_created != null && subscription_created != "") {
            const params = {
                TableName: config.aws_table_name,
                Key: {
                    email: user_email
                },
                /* Item properties will depend on your application concerns */
                UpdateExpression: 'set Paid = :r',
                ExpressionAttributeValues: {
                    ':r': true
                }
            }

            docClient.update(params).promise();

            return true;
        } else {
            const params = {
                TableName: config.aws_table_name,
                Key: {
                    email: user_email
                },
                /* Item properties will depend on your application concerns */
                UpdateExpression: 'set Paid = :r',
                ExpressionAttributeValues: {
                    ':r': false
                }
            }

            docClient.update(params).promise();
        }


        //var subscription_created_datetime = new Date(Number(subscription_created) * 1000);

        //var subscription_created_date = subscription_created_datetime.toLocaleDateString("en-US");

        //var today = new Date();

        //const date_year_sub = subscription_created_date.split("/");

        //if (date_year_sub[0] == (today.getMonth() + 1).toString() && date_year_sub[2] == today.getFullYear().toString()) {
        //    const params = {
        //        TableName: config.aws_table_name,
        //        Key: {
        //            email: user_email
        //        },
        //        /* Item properties will depend on your application concerns */
        //        UpdateExpression: 'set Paid = :r',
        //        ExpressionAttributeValues: {
        //            ':r': true
        //        }
        //    }

        //    docClient.update(params).promise();

        //    return true;
        //}
    }

    return false;

    //subscription_created = document.getElementsByName('uname')[0].value;
}

var usr_count_total:string;
async function get_user_count(user_email:string, user_name:string) {
    const params3 = {
        AttributesToGet: [
            "count"
        ],
        TableName: config.aws_table_name2,
        Key: {
            "type": "users"
        }
    };

    await docClient.get(params3).promise().then(res => usr_count_total = res.Item.count);
    await new Promise(resolve => setTimeout(resolve, 200));

    const params2 = {
        TableName: config.aws_table_name2,
        Key: {
            type: "users"
        },
        /* Item properties will depend on your application concerns */
        UpdateExpression: "ADD #count :c",
        ExpressionAttributeNames: { "#count": "count" },
        ExpressionAttributeValues: { ":c": 1 }
    }

    const params = {
        TableName: config.aws_table_name,
        ConditionExpression: "attribute_not_exists(email)",
        /* Item properties will depend on your application concerns */
        Item: {
            email: user_email,
            folder: 'usr'.concat((Number(usr_count_total) + 1).toString()),
            Paid: false,
            username: user_name
        }
    }

    //docClient.put(params).promise();
    //docClient.update(params2).promise();
    docClient.transactWrite({ TransactItems: [{ Put: params }, { Update: params2 }] }).promise();
    //const temp = usr_count.Item.count;

    //get_user_count().then(res => document.getElementsByName('email')[0].value = res);
    //JSON.parse(usr_count.toString()).count;
}

async function user_exists(user_email: string, user_name: string) {
    const params3 = {
        AttributesToGet: [
            "username"
        ],
        TableName: config.aws_table_name,
        Key: {
            "email": user_email
        }
    };

    var item_exists = false;
    var usr_name;
    await docClient.get(params3).promise().then(res => {
        item_exists = ("Item" in res);
        if (item_exists) {
            usr_name = res.Item.username;
        }
    });

    if (item_exists) {
        if (usr_name == user_name) {
            return "Usernames Match";
        } else {
            return "Incorrect Username Entered";
        }
    } else {
        return "Email does not Exist. Please SignUp";
    }
}

var cus_paid = false;
async function get_user_update_cus(user_email:string, user_name:string) {
    //get_user_count(user_email, user_name);
    const msg = await user_exists(user_email, user_name);
    await new Promise(resolve => setTimeout(resolve, 500));

    if (msg == "Usernames Match") {
        cus_paid = await customer_sub_tot(user_email);
        folder_user = await get_folder(user_email);

        document.getElementById("userLogins").innerHTML = "Logged In";
        document.getElementById("userLogins").style.marginRight = "260px";
        if (cus_paid) {
            document.getElementById("fetch_clothes").hidden = false;
            document.getElementById("delete_cloth").hidden = false;
            document.getElementById("cloth_img").hidden = false;
        } else {
            swal('No Active Subscription Found.\nIf this is a Mistake, Login Again');
        }

        //retrieve_user_models(folder_user);
        window.open("https://pocketclosetar.asia:446/login?folder=" + folder_user + "&email=" + user_email);
        
        //await new Promise(resolve => setTimeout(resolve, 1000));
        
    } else {
        swal("Error", msg, "error");
    }
}

async function retrieve_user_models(folder_name:string) {

    //document.getElementsByName('email')[0].value = __dirname;

    var sources3 = new AWS.S3();
    var dir = __dirname + '/public/' + folder_name + '/upload';
    if (!fs.existsSync(dir)) {
        fs.mkdirSync(dir, { recursive: true });

        try {
            var folder_files = { Bucket: 'pocketclosetbucket', Prefix: folder_name + '/' };
            const data = await sources3.listObjectsV2(folder_files).promise();

            if (data['Contents'].length != 1) {

                for (let index = 1; index < data['Contents'].length; index++) {
                    //console.log(data['Contents'][index]['Key']);
                    var string_path = (data['Contents'][index]['Key']).toString();
                    //console.log(string_path);


                    var params4 = { Bucket: 'pocketclosetbucket', Key: string_path };
                    //var data_list = await sources3.getObject(params4).promise();
                    //var objectStream = await sources3.getObject(params4).promise();
                    var objectStream = sources3.getObject(params4).createReadStream();
                    //var objectStream = fs.createReadStream('../output.png');
                    const writeStream = fs.createWriteStream(__dirname + '/public/' + string_path);
                    objectStream.pipe(writeStream);
                }
            }
        } catch (err) {

        }
    }
    //document.getElementsByName('email')[0].value = data;



}

//async function payment_intent_get(customer_id: string) {
//    const payment_intent = await stripe.paymentIntents.search({
//        query: 'status:\'succeeded\' AND customer:\'' + customer_id + '\'',
//    });

//    return payment_intent.data[0].metadata['order_id'];
//}

//async function customer_sub_get(customer_id: string) {
//    const cus_sub = await stripe.customers.retrieve(customer_id, {
//        expand: ['customer', 'invoice.subscription'],
//    });

//    return cus_sub.invoice.subscription.id;
//}



//export async function getStaticProps(email: string) {
//    const email_bool = await isEmailValid(email);
//    return { email_bool, };
//}

async function get_folder(user_email:string) {
    const params3 = {
        AttributesToGet: [
            "folder"
        ],
        TableName: config.aws_table_name,
        Key: {
            "email": user_email
        }
    };

    var folder_name:string;
    await docClient.get(params3).promise().then(res => folder_name = res.Item.folder);

    return folder_name;
}

var folder_user:string;
async function open_cloth_win() {
    //folder_user = await get_folder(user_email);
    window.open("https://pocketclosetar.asia:444/?folder=" + folder_user, "_self");
    //window.close();
}

document.getElementById('cloth_img').onclick = open_cloth_win;



async function deletemodels() {
    swal({
        title: "Are You Sure?",
        text: "Selected Clothing will be Deleted from your Account",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    })
        .then(async (willDelete) => {
            if (willDelete) {
                var radio_items = document.getElementsByClassName("carousel-item") as HTMLCollectionOf<HTMLInputElement>;

                var radio_items_list = [];

                for (let i = 0; i < radio_items.length; i++) {
                    if (radio_items.item(i)?.checked == true) {
                        var item_split = radio_items.item(i)?.style.cssText.split('/');
                        item_split[item_split.length - 1] = item_split[item_split.length - 1].replace(')', '');
                        item_split[item_split.length - 1] = item_split[item_split.length - 1].replace(';', '');
                        item_split[item_split.length - 1] = item_split[item_split.length - 1].replace('.jpg', '.glb');
                        item_split[item_split.length - 1] = item_split[item_split.length - 1].replace('"', '');
                        radio_items_list.push(item_split[item_split.length - 1].trim());
                    }
                }

                if (radio_items_list.length >= 1) {
                    var win = window.open('about:blank');
                    win.location = "https://pocketclosetar.asia:446/deletemodels?folder=" + folder_user + "&items=" + radio_items_list;
                    await new Promise(resolve => setTimeout(resolve, 5000));
                    //fetch_new_clothes();
                    swal("Deleted Successfully", "Selected Clothing Deleted from Account", "success");
		    await new Promise(resolve => setTimeout(resolve, 5000));
		    //fetch_new_clothes();
		    window.open("https://pocketclosetar.asia", "_self");
                } else {
                    swal("No Clothing Deleted as Nothing was Selected");
                }

                
            } else {
                swal("No Clothing Deleted");
            }
        });
}

document.getElementById('delete_cloth').onclick = deletemodels;



var user_email:string;
document.getElementById('sendtoDB').onclick = async function () {
    user_email = document.getElementsByName('email')[0].value;
    user_email = user_email.toLowerCase();
    const user_name = document.getElementsByName('uname')[0].value;
    const email_validated = email_check.validate(user_email);

    //docClient.put(params).promise();

    if (document.getElementsByName('email')[0].value == "" || !email_validated || (document.getElementsByName('uname')[0].value != "" && (document.getElementsByName('uname')[0].value).toString().length <6)) {
        swal("Error", "Email Cannot be Empty\nValid Email Address also Required\nUsername Must be Atleast 6 Characters Long", "error");
    } else if (document.getElementsByName('uname')[0].value == "") {
	const win = window.open('about:blank');

        const msg = await user_exists(user_email, user_name);

        if (msg == "Incorrect Username Entered") {
            cus_paid = await customer_sub_tot(user_email);
            folder_user = await get_folder(user_email);

            win.location = "https://pocketclosetar.asia:446/login?folder=" + folder_user + "&email=" + user_email;

            var customer_ids = [];
            customer_get(user_email).then(res => { customer_ids = res; });
            await new Promise(resolve => setTimeout(resolve, 500));

            //document.getElementById('email').value = customer_ids[0];

            for (var id of customer_ids) {
                const subscription = await stripe.subscriptions.list({
                    status: "canceled",
                    customer: id,
                });

                const subscription2 = await stripe.subscriptions.list({
                    status: "trialing",
                    customer: id,
                });

                if ((id !=null && subscription.data != null && subscription.data.length >= 1) || (id !=null && subscription2.data != null && subscription2.data.length >= 1)) {
                    var stripe_buttons = document.getElementById('popup');
                    stripe_buttons?.removeChild(stripe_buttons.children[2]);
                    document.getElementById('stripe1')?.insertAdjacentHTML('afterend', '<stripe-buy-button id="stripe2" buy-button-id="buy_btn_1NaABNDMa2VbFuPG9T5ZCTs0" publishable-key="pk_live_51N4srzDMa2VbFuPGS3foJVt0Embj0Kp8CC6bukoRXQs6eEjaUYz2w9ucWTBiODhavCkBQ5ZezkFxOPFNIMjt3TrX00AfzoBMGh" ></stripe-buy-button>');
                    break;
                } else {
                    var stripe_buttons = document.getElementById('popup');
                    stripe_buttons?.removeChild(stripe_buttons.children[2]);
                    document.getElementById('stripe1')?.insertAdjacentHTML('afterend', '<stripe-buy-button id="stripe2" buy-button-id="buy_btn_1NZykEDMa2VbFuPGNHiSWbM8" publishable-key="pk_live_51N4srzDMa2VbFuPGS3foJVt0Embj0Kp8CC6bukoRXQs6eEjaUYz2w9ucWTBiODhavCkBQ5ZezkFxOPFNIMjt3TrX00AfzoBMGh" ></stripe-buy-button>');
                }
            }
	
	    document.getElementsByClassName("subscrips")[0].hidden = false;
	    document.getElementById("cancel_sub").hidden = true;

            document.getElementById("userLogins").innerHTML = "Logged In";
            document.getElementById("userLogins").style.marginRight = "260px";
            if (cus_paid) {
                document.getElementById("fetch_clothes").hidden = false;
            } else {
		document.getElementById("fetch_clothes").hidden = true;
                swal('No Active Subscription Found.\nIf this is a Mistake, Login Again');
            }

            //retrieve_user_models(folder_user);


            //await new Promise(resolve => setTimeout(resolve, 1000));

        } else {
            win.close();
            swal("Error", msg, "error");
        }
    } 
    else {
        const win = window.open('about:blank');

        const msg = await user_exists(user_email, user_name);

        if (msg == "Usernames Match") {
            cus_paid = await customer_sub_tot(user_email);
            folder_user = await get_folder(user_email);

            win.location = "https://pocketclosetar.asia:446/login?folder=" + folder_user + "&email=" + user_email;

            var customer_ids = [];
            customer_get(user_email).then(res => { customer_ids = res; });
            await new Promise(resolve => setTimeout(resolve, 500));

	    for (var id of customer_ids) {
            	const subscription = await stripe.subscriptions.list({
                    status: "canceled",
                    customer: id,
            	});

		const subscription2 = await stripe.subscriptions.list({
                    status: "trialing",
                    customer: id,
                });

            	if ((id !=null && subscription.data != null && subscription.data.length >= 1) || (id !=null && subscription2.data != null && subscription2.data.length >= 1)) {
                    var stripe_buttons = document.getElementById('popup');
                    stripe_buttons?.removeChild(stripe_buttons.children[2]);
                    document.getElementById('stripe1')?.insertAdjacentHTML('afterend', '<stripe-buy-button id="stripe2" buy-button-id="buy_btn_1NaABNDMa2VbFuPG9T5ZCTs0" publishable-key="pk_live_51N4srzDMa2VbFuPGS3foJVt0Embj0Kp8CC6bukoRXQs6eEjaUYz2w9ucWTBiODhavCkBQ5ZezkFxOPFNIMjt3TrX00AfzoBMGh" ></stripe-buy-button>');
		    break;
            	} else {
                    var stripe_buttons = document.getElementById('popup');
                    stripe_buttons?.removeChild(stripe_buttons.children[2]);
                    document.getElementById('stripe1')?.insertAdjacentHTML('afterend', '<stripe-buy-button id="stripe2" buy-button-id="buy_btn_1NZykEDMa2VbFuPGNHiSWbM8" publishable-key="pk_live_51N4srzDMa2VbFuPGS3foJVt0Embj0Kp8CC6bukoRXQs6eEjaUYz2w9ucWTBiODhavCkBQ5ZezkFxOPFNIMjt3TrX00AfzoBMGh" ></stripe-buy-button>');
            	}
	    }
	    document.getElementsByClassName("subscrips")[0].hidden = false;
	    document.getElementById("cancel_sub").hidden = false;
	    
            //window.open("https://10.0.0.130:446/login?folder=" + folder_user + "&email=" + user_email);

            document.getElementById("userLogins").innerHTML = "Logged In";
            document.getElementById("userLogins").style.marginRight = "260px";
            if (cus_paid) {
                document.getElementById("fetch_clothes").hidden = false;
                document.getElementById("delete_cloth").hidden = false;
                document.getElementById("cloth_img").hidden = false;
            } else {
		document.getElementById("fetch_clothes").hidden = true;
                document.getElementById("delete_cloth").hidden = true;
                document.getElementById("cloth_img").hidden = true;
                swal('No Active Subscription Found.\nIf this is a Mistake, Login Again');
            }

            //retrieve_user_models(folder_user);
            

            //await new Promise(resolve => setTimeout(resolve, 1000));

        } else {
            win.close();
            swal("Error", msg, "error");
        }
    }


    //const customers = stripe.customers.search({
    //    query: 'email:\'' + user_email + '\'',
    //});

}


async function fetchAsync(url:string) {
    let response = await fetch(url, { credentials: "same-origin"});
    let data = await response.json();
    return data;
}

var payment_id = "";
//const payment_status_json = JSON.parse(fetchAsync("https://api.stripe.com/v1/payment_intents/" + payment_id));
//const payment_status = payment_status_json.status;

//if (payment_status == "succeeded") {

//}

//const router = express.Router();

//dynamo.addUser({ email: 'aa', folder: 'usr2', Paid: false, username: 'cds'});


//import * as uploadImages from "./get_params.js";
//uploadImages.post('/');



//const readStream = createReadStream('./shirt.jpeg');

//const form = new FormData();
//form.append('photo', readStream);

//const req = request(
//    {
//        host: 'localhost',
//        port: '3000',
//        path: '/',
//        method: 'POST',
//        headers: form.getHeaders(),
//    },
//    response => {
//        console.log(response.statusCode); // 200
//    }
//);

//form.pipe(req);




//const app = express.application;
////Configure Express to use EJS
//app.set("views", path.join(__dirname, "views"));
//app.set("view engine", "ejs");

//// define a route handler for the default home page
//app.get("/", (req, res) => {
//    // render the index template
//    res.render("index");
//});

//fetch('/', { method: 'post' });
//createRequest????????????????????????????



//import * as upload_img from '../get_params.js';

//import dotenv from 'dotenv';

//const app = express();
//dotenv.config();
//const port = '3000';

//app.get('/', (req, res) => {
//    res.send('Express + TypeScript Server');
//});

//app.listen(port, () => {
//    console.log('[server]: Server is running at http://localhost:${port}');
//});

//app.get('/script2/', (req, res) => {
//    res.send("hello");
//    res.render('./index.ts');
//    var data2;
//    // spawn new child process to call the python script
//    const python = spawn.spawn('C:\\Python\\Python38\\python.exe', ['C:\\Users\\ahada\\Desktop\\pose-three-8a313bd48adc019eebb0536540cefe9f\\crop_to_png.py', "C:\\Users\\ahada\\Desktop\\pose-three-8a313bd48adc019eebb0536540cefe9f\\shirt.jpeg"]);
//    // collect data from script
//    python.stdout.on('data', function (data) {
//        console.log('Pipe data from python script ...');
//        data2 = data.toString();
//    });
//    // in close event we are sure that stream from child process is closed
//    python.on('close', (code) => {
//        console.log(`child process close all stdio with code ${code}`);
//        // send data to browser
//        res.send(data2)
//    });
//})

//app.listen(port);



//const app: Application = express();

//app.get('/', (req: Request, res: Response) => {
//    res.send('hello');
//});

//app.listen(3000, () => console.log('Server running'));


//const app = express();

//app.get('/', (req: Request, res: Response) => {
//    res.sendFile(`${__dirname}/index.html`);
//});

//app.use(express.json());
//app.use(express.urlencoded({ extended: true }));
//app.use(uploadRouter);

//app.listen(3000, () => {
//    console.log('Form running on port 3000');
//});

//upload_img;

async function listFilesFromS3(s3Request: AWS.S3.ListObjectsV2Request, allKeys: Array<string>) {
    //console.info("---- LISTING S3 BUCKET", JSON.stringify(s3Request, null, 2));

    try {
        const data: AWS.S3.ListObjectsV2Output = await sources3.listObjectsV2(s3Request).promise();
        let contents = data.Contents;
        contents.forEach(async function (content) {
            await allKeys.push(await content.Key);
        });
        if (data.IsTruncated) {
            s3Request.ContinuationToken = data.NextContinuationToken;
            //console.log("get further list...");
            return listFilesFromS3(s3Request, allKeys);
        } else {
            return allKeys;
        }
    } catch (error) {
        //console.log(error);
        return error;
    }
}

async function retrieve_user_models2(folder_name: string) {

    //document.getElementsByName('email')[0].value = __dirname;


    var data = await listFilesFromS3(
        {
            Bucket: "pocketclosetbucket",
            Prefix: folder_name.concat('/')
        },
        []
    );
    await new Promise(resolve => setTimeout(resolve, 500));
    document.getElementsByName('email')[0].value = data[0];
    //document.getElementsByName('email')[0].value = data;



}

//async function retrieve_user_models(folder_name: string) {

//    //document.getElementsByName('email')[0].value = __dirname;

//    var dir = 'C:/Users/ahada/Desktop/pose-three-5b15be4b6a8f12ac79afcb328147cca1/public/' + folder_name;
//    if (!fs.existsSync(dir)) {
//        fs.mkdirSync(dir);
//    }
    
//    try {
//        var folder_files = { Bucket: 'pocketclosetbucket', Prefix: folder_name + '/' };
//        const data = await sources3.listObjectsV2(folder_files).promise();

//        var normal: Array<string> = [];
//        let contents = data.Contents;
//        contents.forEach(function (content) {
//            normal.push(content.Key);
//        });

//        document.getElementsByName('email')[0].value = normal[0];
//    } catch (err) {

//    }

//    await sources3.listObjectsV2(folder_files).promise().then(res => {
//        document.getElementsByName('email')[0].value = res['Contents'][index]['Key'];

//        for (let index = 1; index < res['Contents'].length; index++) {
//            //console.log(data['Contents'][index]['Key']);
//            var string_path = (res['Contents'][index]['Key']).toString();
//            //console.log(string_path);

//            if (index == 1) {
//                document.getElementsByName('email')[0].value = string_path;
//            }

//            var params4 = { Bucket: 'pocketclosetbucket', Key: string_path };
//            //var data_list = await sources3.getObject(params4).promise();
//            //var objectStream = await sources3.getObject(params4).promise();
//            var objectStream = sources3.getObject(params4).createReadStream();
//            //var objectStream = fs.createReadStream('../output.png');
//            const writeStream = fs.createWriteStream('../public/' + string_path);
//            objectStream.pipe(writeStream);
//        }
//    });
//    //document.getElementsByName('email')[0].value = data;

    

//}


// Engine
const engine = new PoseEngine();
const token = location.hostname === "localhost" ?
    "rxkBmicqUhinSPsroYvrtsaUDHTVkAkc" : "SHm1VmOj2p9QH1sbwQKXxXk1pkC8MbgD";

// Parameters
const urlParams = new URLSearchParams(window.location.search);
let rear = urlParams.has("rear");

//PoseOutfitPlugin.apply("eric_rigged_maximo.glb");


// Model map
var modelMap: {
    [key: string]: {
        file: string, avatar: boolean,
        outfit?: OutfitParams
    }
} = {};
var model:string;
var avatar:boolean;

for (let index = 1; index <= 30; index++) {

    if (fileExists('texturedd' + index.toString() + '.glb')) {
        modelMap[index.toString()] = {
            file: 'texturedd' + index.toString() + '.glb', avatar: false,
            outfit: {
                occluders: [/Head$/, /Body/],
                hidden: [/Eye/, /Teeth/, /Hair/, /Footwear/, /Glasses/]
            }
        };

        var inp = document.createElement('input');
        inp.type = 'radio';
        inp.name = 'model' + index.toString();
        inp.value = index.toString();
        inp.setAttribute('data-waschecked', 'false');
        inp.style.cssText = "background-image: url(preview/texturedd" + index.toString() + ".jpg);";
        inp.className = "carousel-item";
        document.getElementById('courosel')?.appendChild(inp);
    }
}

model = "1";
avatar = modelMap[model].avatar;

//fetch('/', async (req: Request, res: Response) => {
//    user_email = req.query.email;

//    cus_paid = await customer_sub_tot(user_email);
//    folder_user = await get_folder(user_email);
//    await new Promise(resolve => setTimeout(resolve, 500));
//});

fetch('https://10.0.0.130:3000/?email=test@gmail.com')
    .then(request => request.json())
    .then(request => {
        user_email = request.query.email;
        // Do something with response.
    });

//user_email = express.request.query.email;

//const app: Application = express();

//const response = await fetch('https://api.github.com/users/github');
//const data = await response.json();

//var app = express();
//app.get('/', function (req: express.Request, res: express.Response) { });


function fileExists(file_url:string) {

    var http = new XMLHttpRequest();

    http.open('HEAD', file_url, false);
    http.send();

    return http.status != 404;

}


document.getElementById('fetch_clothes').onclick = fetch_new_clothes;

async function fetch_new_clothes() {
    //cus_paid = await customer_sub_tot(user_email);
    folder_user = await get_folder(user_email);

    await new Promise(resolve => setTimeout(resolve, 600));
    //document.getElementsByName('email')[0].value = cus_paid + folder_user;

    
    if (fileExists(folder_user + '/textured1.glb')) {
        modelMap = {};

        var remove_childs = document.getElementById('courosel');
        remove_childs?.replaceChildren();

        ////window.request({});
        //__webpack_public_path__ = '../user_data/';


        for (let index = 1; index <= 30; index++) {

            if (fileExists(folder_user + '/textured' + index.toString() + '.glb')) {
                modelMap[index.toString()] = {
                    file: folder_user + '/textured' + index.toString() + '.glb', avatar: false,
                    outfit: {
                        occluders: [/Head$/, /Body/],
                        hidden: [/Eye/, /Teeth/, /Hair/, /Footwear/, /Glasses/]
                    }
                };

                //var temp = index.toString();
                //modelMap = {
                //    temp: {
                //        file: folder_user + '/textured' + index.toString() + '.glb', avatar: false,
                //        outfit: {
                //            occluders: [/Head$/, /Body/],
                //            hidden: [/Eye/, /Teeth/, /Footwear/, /Glasses/]
                //        }
                //    }
                //};

                var inp = document.createElement('input');
                inp.type = 'radio';
                inp.name = 'model' + index.toString();
                inp.value = index.toString();
                inp.setAttribute('data-waschecked', 'false');
                inp.style.cssText = "background-image: url(" + folder_user + "/upload/" + "textured" + index.toString() + ".jpg);";
                //inp.style.backgroundImage = "url(preview/prev_model.JPG)";
                inp.className = "carousel-item";
                document.getElementById('courosel')?.appendChild(inp);
            }
        }
        await new Promise(resolve => setTimeout(resolve, 1500));

        model = "1";
        avatar = modelMap[model].avatar;
        //modelMap["onesie"].avatar = false;
        //modelMap["jacket"].avatar = false;

        await renderer.setOutfit(
            modelMap[model].file,
            avatar ? undefined : modelMap[model].outfit);
        await renderer2.setOutfit(
            modelMap[model].file,
            avatar ? undefined : modelMap[model].outfit);

        //container.replaceChildren();
        //await Promise.all([
        //    engine.removeRenderer(renderer),
        //    engine.removeRenderer(renderer2),
        //    engine.reset(),
        //    engine.init({ token: token })
        //]);

        // Model carousel
        var modelBtns = document.getElementsByClassName(
            "carousel-item") as HTMLCollectionOf<HTMLInputElement>;
        for (let i = 0; i < modelBtns.length; i++) {
            modelBtns.item(i).onclick = () => {
                if (modelBtns.item(i)?.dataset.waschecked == 'false') {
                    modelBtns.item(i).dataset.waschecked = 'true';
                } else if (modelBtns.item(i)?.dataset.waschecked == 'true') {
                    modelBtns.item(i).dataset.waschecked = 'false';
                    modelBtns.item(i).checked = false;
                }
            }
            modelBtns.item(i).onchange = async () => {
                if (modelBtns.item(i).checked && modelMap[modelBtns.item(i).value]) {
                    var checked_count = 0;
                    for (let i = 0; i < modelBtns.length; i++) {
                        modelBtns.item(i).disabled = true;
                        if (modelBtns.item(i).checked) { checked_count++; }
                    }
                    //outfitSwitch.disabled = true;
                    const spinner = createSpinner();
                    document.body.appendChild(spinner);
                    model = modelBtns.item(i).value;
                    avatar = modelMap[model].avatar;
                    if (checked_count == 2) {
                        await renderer2.setOutfit(
                            modelMap[model].file,
                            avatar ? undefined : modelMap[model].outfit);
                    } else {
                        await renderer.setOutfit(
                            modelMap[model].file,
                            avatar ? undefined : modelMap[model].outfit);
                        await renderer2.setOutfit(
                            modelMap[model].file,
                            avatar ? undefined : modelMap[model].outfit);
                    }
                    //outfitSwitch.checked = avatar;
                    document.body.removeChild(spinner);
                    for (let i = 0; i < modelBtns.length; i++) {
                        modelBtns.item(i).disabled = false;
                    }
                    //outfitSwitch.disabled = false;
                }
            };
        }

        //await main();
    } else {
        swal("No Clothing Found. Please Upload Clothing to Fetch");
    }
}


// Create spinner element
function createSpinner() {
    const container = document.createElement("div");
    container.className = "spinner-container";
    container.id = "spinner";
    const spinner = document.createElement("div");
    spinner.className = "spinner";
    for (let i = 0; i < 6; i++) {
        const dot = document.createElement("div");
        dot.className = "spinner-dot";
        spinner.appendChild(dot);
    }
    container.appendChild(spinner);
    return container;
}


document.getElementById('cancel_sub').onclick = async function () { await cancel_subscription(user_email) };

async function cancel_subscription(user_email: string) {

    if (user_email != undefined) {
        swal({
            title: "Are You Sure?",
            text: "Active Subscriptions will Cancel at Period End. Free Trials will Cancel Immediately",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    var customer_id = [];
                    customer_get(user_email).then(res => { customer_id = res; });

                    await new Promise(resolve => setTimeout(resolve, 500));

                    var val = 0;
                    for (var id of customer_id) {
                        const subscription = await stripe.subscriptions.list({
                            status: "active",
                            customer: id,
                        });

                        if (subscription.data[0] == null) {

                            const subscription2 = await stripe.subscriptions.list({
                                status: "trialing",
                                customer: id,
                            });

                            if (subscription2.data[0] != null) {
                                const update_sub = await stripe.subscriptions.cancel(
                                    subscription2.data[0].id);

                                swal("Cancelled Successfully", "Subscription Cancelled Now", "success");
                                break;
                            }
                        }
                        else {
                            const update_sub = await stripe.subscriptions.update(
                                subscription.data[0].id,
                                { cancel_at_period_end: true }
                            );
                            swal("Cancelled Successfully", "Subscription Will Cancel at Period End", "success");
                            break;
                        }
                        val++;
                    }

                    if (val == customer_id.length) {
                        swal("Subscription NOT Cancelled as No Active Subscription Found.\nIf this is a Mistake, Cancel Again");
                    }
                } else {
                    swal("Subscription NOT Cancelled");
                }
            });

        //var confirmed: boolean = confirm("Are you Sure?");

        //if (confirmed) {
        //    var customer_id: string;
        //    customer_get(user_email).then(res => { customer_id = res; });

        //    await new Promise(resolve => setTimeout(resolve, 500));

        //    const subscription = await stripe.subscriptions.list({
        //        status: "active",
        //        customer: customer_id,
        //    });

        //    await new Promise(resolve => setTimeout(resolve, 500));

        //    const update_sub = await stripe.subscriptions.update(
        //        subscription.data[0].id,
        //        { cancel_at_period_end: true }
        //    );

        //    swal("Cancelled Successfully", "Subscription Will Cancel at Period End", "success");
        //}
    } else {
        swal("Error", "Please Login First", "error");
    }
}


document.getElementById('help').onclick = async function () {
    var contents = "Subscription Needed to Upload & Delete Own Clothing.<br />Signup & Login to View Subscriptions.<br />Once Subscription is Purchased, Login Again.<br />After Uploading Clothes, Login Again and 'Fetch Clothing' to View Them.<br /><br /><strong>Use Same Email for Purchasing Subscription as for Login</strong><br /><br />2 Pieces of Clothing can be Selected at the Same Time, after which, Deselect Both Clothing to Select New Ones.<br /><br /><br /><strong>Disclaimer:</strong> Jacket and Suit only for Demo Purposes. Not Included in Subscription Yet.<br />Quality of 3D Models Depend on Quality of Images Uploaded and How Images are Cropped. Make sure Clothing is Clearly Visible and is Oriented Straight Down.";

    swal({
        title: "Help",
        content: {
            element: "div",
            attributes: {
                innerHTML: contents,
                style: "text-align: left;",
            },
        },
    });
};

//application.use(
//    fileupload()
//);
//application.post('/saveImage', (req, res) => {
//    const fileName = req.files.myFile.name
//    const path = __dirname + '/images/' + fileName

//    image.mv(path, (error) => {
//        if (error) {
//            console.error(error)
//            res.writeHead(500, {
//                'Content-Type': 'application/json'
//            })
//            res.end(JSON.stringify({ status: 'error', message: error }))
//            return
//        }

//        res.writeHead(200, {
//            'Content-Type': 'application/json'
//        })
//        res.end(JSON.stringify({ status: 'success', path: '/img/houses/' + fileName }))
//    })
//});

var renderer: AvatarRenderer;
var renderer2: AvatarRenderer;
var container: HTMLElement;
async function main() {
    // Renderer
    container = document.getElementById("root");

    //dotenv.config();

    //application.get('/', function (req, res) {
    //    alert('HELLO');
    //});

    //const server = fastify();

    //application.get('/ping', async (request, reply) => {
    //    return 'pong\n'
    //})

    //application.listen({ port: 3000 });

    //upload_img;
    

    


    const img = document.getElementById("cloth_img") as HTMLInputElement;
    img.onchange = async () => {

        //var download = function (uri, filename, callback) {
        //    request.head(uri, function (err, res, body) {
        //        console.log('content-type:', res.headers['content-type']);
        //        console.log('content-length:', res.headers['content-length']);

        //        request(uri).pipe(fs.createWriteStream(filename)).on('close', callback);
        //    });
        //};

        //download('https://www.google.com/images/srpr/logo3w.png', 'google.png', function () {
        //    console.log('done');
        //});


        //send_img(img.files[0]);
        //send_img();

        //const response = await fetch('https://10.0.0.130:3000', {
        //    method: 'POST',
        //    body: img.files[0],
        //    headers: { 'Content-Type': 'image/jpeg' }
        //});

        //if (!response.ok) { /* Handle */ }

        //// If you care about a response:
        //if (response.body !== null) {
        //    // body is ReadableStream<Uint8Array>
        //    // parse as needed, e.g. reading directly, or
        //    const asString = new TextDecoder("utf-8").decode(response.body);
        //    // and further:
        //    const asJSON = JSON.parse(asString);  // implicitly 'any', make sure to verify type on runtime.
        //    console.log(asJSON);
        //}


        const img_path = img.files[0];
        //var img_path = fs.readFileSync(img.files[0]);
        //var base64 = img_path.toString("base64");
        //const img_path = "C:\\Users\\ahada\\Desktop\\pose-three-8a313bd48adc019eebb0536540cefe9f\\shirt.jpeg";

        //Here are the option object in which arguments can be passed for the python_test.js.
        let options = {
            mode: 'text',
            pythonOptions: ['-u'], // get print results in real-time
            pythonPath: 'C:\\Python\\Python38\\python.exe',
            scriptPath: 'C:\\Users\\ahada\\Desktop\\pose-three-8a313bd48adc019eebb0536540cefe9f\\', //If you are having python_test.py script in same folder, then it's optional.
            args: [img_path] //An argument which can be accessed in the script using sys.argv[1]
        };

        //var py = spawn.spawn('C:\\Python\\Python38\\python.exe', ['C:\\Users\\ahada\\Desktop\\pose-three-8a313bd48adc019eebb0536540cefe9f\\crop_to_png.py', "C:\\Users\\ahada\\Desktop\\pose-three-8a313bd48adc019eebb0536540cefe9f\\shirt.jpeg"]);
        //var py = spawn.spawn('\\\\10.0.0.130:3000\\C$\\Python\\Python38\\python.exe', ['crop_to_png.py', "shirt.jpeg"]);
        //py.on;
        //spawn.exec('\\\\10.0.0.130:3000\\C$\\Users\\ahada\\Desktop\\pose-three-8a313bd48adc019eebb0536540cefe9f\\crop_to_png.py \\\\10.0.0.130:3000\\C$\\Users\\ahada\\Desktop\\pose-three-8a313bd48adc019eebb0536540cefe9f\\shirt.jpeg');
        spawn.exec('C:\\Users\\ahada\\Desktop\\pose-three-8a313bd48adc019eebb0536540cefe9f\\crop_to_png.py C:\\Users\\ahada\\Desktop\\pose-three-8a313bd48adc019eebb0536540cefe9f\\shirt.jpeg');
        //PythonShell.run('crop_to_png.py', { mode: 'text', pythonPath: 'C:\\Python\\Python38\\python.exe', scriptPath: 'C:\\Users\\ahada\\Desktop\\pose-three-8a313bd48adc019eebb0536540cefe9f\\', args: [img_path] });

        spawn.spawn('node', ['C:\\Users\\ahada\\Desktop\\pose-three-8a313bd48adc019eebb0536540cefe9f\\python.js']);
        await saveAs(img_path, "user_outfit11.png");



        //img_user.appendChild(document.createTextNode(img.files[0].name));

        /* This prints out the image on screen, but doesn't work
         * 
        img_user.src = "file:/storage/emulated/0/DCIM/Camera/" + img.files[0].name;
        img_user.style.visibility = "visible";*/

    }


    const img_user = document.createElement("img");

    //img_user.appendChild(img);
    img_user.width = 300;
    img_user.height = 320;
    img_user.className = "img_returned";
    img_user.style.visibility = "hidden";

    const currentDiv = document.getElementById("outline");
    document.body.insertBefore(img_user, currentDiv);


    const shirt_out = document.getElementById("shirt_out") as HTMLElement;
    const pants_out = document.getElementById("pants_out") as HTMLElement;

    //const outlineBtns = document.getElementsByName(
    //    "outline_type") as NodeListOf<HTMLInputElement>;

    //outlineBtns.item(0).onclick = async () => {
    //    if (shirt_out.style.visibility == "hidden") {
    //        shirt_out.style.visibility = "visible";
    //    }
    //    else {
    //        shirt_out.style.visibility = "hidden";
    //        outlineBtns.item(0).checked = false;
    //    }
    //    pants_out.style.visibility = "hidden";
    //}

    //outlineBtns.item(1).onclick = async () => {
    //    shirt_out.style.visibility = "hidden";
    //    if (pants_out.style.visibility == "hidden") {
    //        pants_out.style.visibility = "visible";
    //    }
    //    else {
    //        pants_out.style.visibility = "hidden";
    //        outlineBtns.item(1).checked = false;
    //    }
    //}


    //outlineBtns.forEach((btn) => {
    //    btn.onchange = async () => {
    //        if (btn.checked) {

    //        }
    //    }
    //});

    //// create a new div element
    //const img_lbl = document.createElement("div");

    //// and give it some content
    //const newContent = document.createTextNode(String(img?.nodeName));

    //// add the text node to the newly created div
    //img_lbl.appendChild(newContent);
    //img_lbl.className = "img_returned";
    //img_lbl.id = "img_returned";

    //// add the newly created element and its content into the DOM
    //const currentDiv = document.getElementById("courosel");
    //document.body.insertBefore(img_lbl, currentDiv);

    if (!container)
        return;
    renderer = new AvatarRenderer(
        container, "crop", !rear, modelMap[model].file,
        avatar ? undefined : modelMap[model].outfit);
    renderer2 = new AvatarRenderer(
        container, "crop", !rear, modelMap[model].file,
        avatar ? undefined : modelMap[model].outfit);
    // Camera switch
    var cameraSwitch = document.getElementById(
        "camera-switch") as HTMLButtonElement | null;
    if (cameraSwitch) {
        cameraSwitch.onclick = async () => {
            cameraSwitch.disabled = true;
            rear = !rear;
            await engine.setup({ size: { width: 1920, height: 1080 }, rear });
            await engine.start();
            renderer.setMirror(!rear);
            renderer2.setMirror(!rear);
            cameraSwitch.disabled = false;
        }
    }
    // Outfit switch
    //var outfitSwitch = document.getElementById(
    //    "outfit-switch") as HTMLInputElement;
    //outfitSwitch.checked = avatar;
    //outfitSwitch.onchange = async () => {
    //    modelBtns.forEach((btn) => { btn.disabled = true; })
    //    outfitSwitch.disabled = true;
    //    const spinner = createSpinner();
    //    document.body.appendChild(spinner);
    //    avatar = outfitSwitch.checked;
    //    await renderer.setOutfit(
    //        modelMap[model].file,
    //        avatar ? undefined : modelMap[model].outfit);
    //    document.body.removeChild(spinner);
    //    modelBtns.forEach((btn) => { btn.disabled = false; });
    //    outfitSwitch.disabled = false;
    //}
    // Recorder
    //var safari = navigator.userAgent.indexOf('Safari') > -1 &&
    //    navigator.userAgent.indexOf('Chrome') <= -1
    //const ext = safari ? "mp4" : "webm";
    //const recorder = new Recorder(renderer, "video/" + ext);
    //const recordButton = document.getElementById(
    //    "record") as HTMLButtonElement | null;
    //if (recordButton)
    //    recordButton.onclick = () => {
    //        recorder?.start();
    //        setTimeout(async () => {
    //            const blob = await recorder?.stop();
    //            if (!blob)
    //                return;
    //            const url = URL.createObjectURL(blob);
    //            const link = document.createElement("a");
    //            link.hidden = true;
    //            link.href = url;
    //            link.download = "capture." + ext;
    //            link.click();
    //            link.remove();
    //            URL.revokeObjectURL(url);
    //        }, 10000);
    //    };
    // Model carousel
    var modelBtns = document.getElementsByClassName(
        "carousel-item") as HTMLCollectionOf<HTMLInputElement>;
    for (let i = 0; i < modelBtns.length; i++) {
        modelBtns.item(i).onclick = () => {
            if (modelBtns.item(i)?.dataset.waschecked == 'false') {
                modelBtns.item(i).dataset.waschecked = 'true';
            } else if (modelBtns.item(i)?.dataset.waschecked == 'true') {
                modelBtns.item(i).dataset.waschecked = 'false';
                modelBtns.item(i).checked = false;
            }
        }
        modelBtns.item(i).onchange = async () => {
            if (modelBtns.item(i).checked && modelMap[modelBtns.item(i).value]) {
                var checked_count = 0;
                for (let i = 0; i < modelBtns.length; i++) {
                    modelBtns.item(i).disabled = true;
                    if (modelBtns.item(i).checked) { checked_count++; }
                }
                //outfitSwitch.disabled = true;
                const spinner = createSpinner();
                document.body.appendChild(spinner);
                model = modelBtns.item(i).value;
                avatar = modelMap[model].avatar;
                if (checked_count == 2) {
                    await renderer2.setOutfit(
                        modelMap[model].file,
                        avatar ? undefined : modelMap[model].outfit);
                } else {
                    await renderer.setOutfit(
                        modelMap[model].file,
                        avatar ? undefined : modelMap[model].outfit);
                    await renderer2.setOutfit(
                        modelMap[model].file,
                        avatar ? undefined : modelMap[model].outfit);
                }
                //outfitSwitch.checked = avatar;
                document.body.removeChild(spinner);
                for (let i = 0; i < modelBtns.length; i++) {
                    modelBtns.item(i).disabled = false;
                }
                //outfitSwitch.disabled = false;
            }
        };
    }
    // Initialization
    await Promise.all([ 
        engine.addRenderer(renderer),
        engine.addRenderer(renderer2),
        engine.init({ token: token })
    ]);
    await engine.setup({ size: { width: 1920, height: 1080 }, rear });
    await engine.start();
    document.getElementById("dots")?.remove();

}
main();
